import React, {lazy, Suspense, useEffect} from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch, HashRouter,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';
import Auth0Callback from "@iso/containers/Authentication/Auth0/Auth0Callback";
import OrderApprovementScreen from "./containers/OrderApprovementScreen/OrderApprovementScreen";

const  Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
const  CreateOrder = lazy(() => import('./containers/CreateOrder/CreateOrder'));
const  CreateProposal = lazy(() => import('./containers/CreateProposal/CreateProposal'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.AUTH0_CALLBACK,
    exact: false,
    component: Auth0Callback
  },
  {
    path: PUBLIC_ROUTE.ORDER_APPROVEMENT,
    exact: false,
    component: OrderApprovementScreen
  },
];
function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector(state => state.Auth.idToken);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default function Routes() {
  // alert("check" + window.location.href)

  useEffect(() => {
    //@ts-ignore
    if (typeof window !== "undefined" && typeof window.markerConfig === "undefined") {
      //@ts-ignore
      window.markerConfig = {
        destination: '63ef4a657e410184d54ee76b',
        source: 'snippet'
      };
      //@ts-ignore
      /* eslint-disable no-unused-expressions */
      !function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);
    }
  })

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
